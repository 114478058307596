import { DataGrid, ptBR } from '@mui/x-data-grid';
import {
    fromFloatToCurrency,
    formatDateToPtLocaleWithTime
} from '../../utils/format';
import clsx from 'clsx';
import applyTooltipToColumns from '../../utils/applyTooltipToColumn';

const sizeCol = 150;
const columns = [
    {
        field: 'date',
        headerName: 'Data',
        minWidth: sizeCol,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        renderCell: (params) => formatDateToPtLocaleWithTime(params.row.date),
        valueSetter: (params) =>
            new Date(params.row.date).toLocaleString('pt-BR')
    },
    {
        field: 'description',
        headerName: 'Pedido',
        minWidth: 600,
        headerAlign: 'left',
        overflow: '',
        flex: 1,
        align: 'left'
    },
    {
        field: 'amount',
        headerName: 'Valor',
        renderCell: (params) => fromFloatToCurrency(params.row.amount),
        minWidth: sizeCol,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        cellClassName: (params) => {
            if (params.value == null) {
                return '';
            }
            return clsx('number-handler', {
                negative: params.value < 0,
                positive: params.value > 0
            });
        }
    }
];

export default function ExtractGrid({ rows, loading = false }) {
    return (
        <div style={{ height: 440, width: '100%' }}>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                disableSelectionOnClick
                columns={applyTooltipToColumns(columns, 100)}
                pageSize={5}
                getRowId={() => parseInt(Date.now() * Math.random()).toString()}
                rowsPerPageOptions={[5]}
                loading={loading}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'date', sort: 'desc' }]
                    }
                }}
            />
        </div>
    );
}
