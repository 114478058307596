import React from 'react';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import { EditButton } from '../EditButton';
import { DocumentButton } from '../DocumentButton';
import { fromFloatToCurrency } from '../../utils/format';
import { Typography } from '@mui/material';
import clsx from 'clsx';

const sizeCol = 150;
const columns = [
    {
        field: 'created',
        headerName: 'Data',
        minWidth: sizeCol,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        valueGetter: (params) => {
            const dateString = params.value;
            const [day, month, year] = dateString.split('/');
            return new Date(`${year}-${month}-${day}`);
        },
        renderCell: (params) => {
            const date = new Date(params.value);
            return date.toLocaleDateString('pt-BR');
        }
    },
    {
        field: 'description',
        headerName: 'Pedido',
        minWidth: 600,
        headerAlign: 'left',
        overflow: '',
        align: 'left',
        flex: 1
    },
    {
        field: 'totalAmount',
        headerName: 'Valor',
        renderCell: (params) => fromFloatToCurrency(params.row.totalAmount),
        minWidth: sizeCol,
        headerAlign: 'left',
        align: 'left',
        flex: 1,
        cellClassName: (params) => {
            if (params.value == null) {
                return '';
            }

            return clsx('number-handler', {
                negative: params.value < 0,
                positive: params.value > 0
            });
        }
    }
];

export default function ExtractGridOrder({ rows, loading }) {
    return (
        <div style={{ height: 440, width: '100%' }}>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={rows}
                disableSelectionOnClick
                columns={columns}
                pageSize={5}
                getRowId={() => parseInt(Date.now() * Math.random()).toString()}
                rowsPerPageOptions={[5]}
                loading={loading}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'created', sort: 'desc' }]
                    }
                }}
            />
        </div>
    );
}
