import React from 'react';
import { Button } from '@mui/material';
import * as XLSX from 'xlsx';

const ExportToExcelButton = ({
    columns,
    rows,
    filename = 'export',
    children
}) => {
    const exportToExcel = () => {
        const translatedAndFilteredData = rows.map((row) => {
            const filteredRow = {};
            Object.keys(columns).forEach((key) => {
                filteredRow[columns[key]] = row[key];
            });
            return filteredRow;
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(translatedAndFilteredData);
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename.endsWith('.xlsx') ? filename : `${filename}.xlsx`;
        a.click();
        URL.revokeObjectURL(url);
    };

    return (
        <Button
            variant="outlined"
            size="medium"
            sx={{
                borderWidth: '2px',
                maxHeight: '60px',
                alignSelf: 'center'
            }}
            onClick={exportToExcel}
        >
            {children}
        </Button>
    );
};

export default ExportToExcelButton;
