import React from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import CollaboratorGrid from '../../components/grids/CollaboratorGrid';
import Header from '../../components/Header';

// Store
import { useDispatch, useSelector } from 'react-redux';
import { GetAllAccountHoldersByCompanyId } from '../../store/_Entities/AccountHolder';
import { GetInviteUsers } from '../../store/_Entities/InviteUser';
import { GetUsers } from '../../store/_Entities/User';

const Collaborators = () => {
    const { user, accountHolder, inviteUser } = useSelector(
        (state) => state.entities
    );

    const dispatch = useDispatch();

    React.useMemo(() => {
        dispatch(GetAllAccountHoldersByCompanyId(user.data?.companyID));
        dispatch(GetInviteUsers(user.data?.companyID));
        dispatch(GetUsers(user.data?.companyID, 1));
        dispatch(GetUsers(user.data?.companyID, 0));
    }, []);

    const transFormData = () => {
        const accountHoldersFormated =
            accountHolder.companyAccountHolders?.map((user) => ({
                created: user.created,
                id: user.userID,
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                document: user.document,
                kycStatus: user.kycStatus
            })) || [];

        const formatUsers = (users, getUserData) =>
            users
                ?.map(getUserData)
                .filter(
                    (user) =>
                        !accountHoldersFormated.some((ah) => ah.id === user.id)
                ) || [];

        const activeUsersFormated = formatUsers(user.activeUsers, (user) => ({
            created: user.updated,
            id: user.userID,
            name: user.name,
            email: user.email,
            document: user.registration,
            kycStatus: 'Pendente de KYC'
        }));

        const inviteUsersFormated = formatUsers(
            inviteUser.inviteUsers,
            (user) => ({
                created: user.created,
                id: user.inviteUsersID,
                inviteUsersID: user.inviteUsersID,
                name: user.name,
                email: user.email,
                document: '*',
                kycStatus: 'Cadastro pendente'
            })
        );

        const inactiveUsersFormated = formatUsers(
            user.inactiveUsers,
            (user) => ({
                created: user.updated,
                id: user.userID,
                name: user.name,
                email: user.email,
                document: user.registration,
                kycStatus: 'Inativo'
            })
        );

        return [
            ...accountHoldersFormated,
            ...activeUsersFormated,
            ...inviteUsersFormated,
            ...inactiveUsersFormated
        ];
    };

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <Header backButton title="Colaboradores" />
                <Grid container rowGap={8}>
                    <Grid
                        item
                        xs={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            gap: '1rem'
                        }}
                    >
                        <Button
                            href={`/collaborators/invite/${user.data?.companyID}`}
                            variant="contained"
                        >
                            Cadastrar Colaborador
                        </Button>
                        <Button
                            href="/collaborators/batching"
                            variant="contained"
                        >
                            Cadastrar Colaborador em lote
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <CollaboratorGrid
                            data={transFormData() || []}
                            loading={
                                accountHolder.loading ||
                                inviteUser.loading ||
                                user.loading
                            }
                        />
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default Collaborators;
