import React from 'react';

// Wexp Components
import ResponsiveAppBar from '../../../components/NavBar';
import BackButton from '../../../components/BackButton';
import Footer from '../../../components/Footer';
import Totalizer from '../../../components/Totalizer';
import { TRANSFER_RESET } from '../../../store/_Entities/Transfer';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Card, CardContent, Typography, Tabs, Tab } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';

// import CurrencyInput from 'react-currency-input';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

// react-router
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { GetUserBalanceAccountGroup } from '../../../store/_Entities/BalanceAccountGroup';
import {
    clearLoading,
    startLoading,
    stopLoading
} from '../../../store/_UI/Loading';
import { WexpOrange } from '../../../assets/theme';
import { setBenefits, setTotal } from '../../../store/_Forms/NewOrder';

// Store
import { fromFloatToCurrency } from '../../../utils/format';
import TabPanel from '../../../components/TabPanel';
import BalanceTab from '../../../components/tabs/DetailCollaborator/BalanceTab';
import UserExtractGridOrder from '../../../components/grids/UserExtractGridOrder';

const innerTheme = createTheme({
    palette: {
        primary: {
            main: WexpOrange[500]
        }
    }
});

const CreateNewOrder = () => {
    const [orderRequest, setOrderRequest] = React.useState({});
    const [orderYouRequest, setOrderYouRequest] = React.useState({});
    const [orderCorpRequest, setOrderCorpRequest] = React.useState({});
    const [selectedUserInfo, setSelectedUserInfo] = React.useState(null);
    const [selectedPorduct, setSelectedPorduct] = React.useState(0);
    const { newOrder } = useSelector((state) => state.forms);
    const [tab, setTab] = React.useState(0);

    const routeParams = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { companyAccountHolders } = useSelector(
        (state) => state.entities.accountHolder
    );
    const { balanceAccountGroup, treasuryAccountHolder } = useSelector(
        (state) => state.entities
    );

    const [currentTreasuryAccountBalance, setCurrentTreasuryAccountBalance] =
        React.useState(
            treasuryAccountHolder.treasuryAccountHolder?.treasuryAccounts[0]
                ?.balanceAccounts[0]?.balance
        );

    const [orderTotalValue, setOrderTotalValue] = React.useState(0);
    const [youTotalValue, setYouTotalValue] = React.useState(0);
    const [corpTotalValue, setCorpTotalValue] = React.useState(0);

    React.useEffect(() => {
        dispatch(GetUserBalanceAccountGroup(routeParams.userID));
        dispatch(TRANSFER_RESET());
    }, []);

    React.useEffect(() => {
        if (balanceAccountGroup.loading) {
            dispatch(startLoading());
        } else {
            dispatch(clearLoading());
        }
    }, [balanceAccountGroup.loading]);

    React.useEffect(() => {
        let userID = routeParams.userID;
        // TODO -> Swap the mock values for the values coming from the redux store
        let userFound = companyAccountHolders.find(
            (user) => user.userID === userID
        );

        setSelectedUserInfo(userFound);
    }, [companyAccountHolders, routeParams]);

    React.useEffect(() => {
        if (balanceAccountGroup.userBalanceAccounts?.length > 0) {
            let orderRequest = {};

            for (
                let i = 0;
                i < balanceAccountGroup.userBalanceAccounts?.length;
                i++
            ) {
                orderRequest[
                    balanceAccountGroup.userBalanceAccounts[i].balanceAccountID
                ] = '';

                //set you and corp balances
                if (
                    balanceAccountGroup.userBalanceAccounts[i]
                        .balanceAccountTypeID == 1
                ) {
                    orderYouRequest[
                        balanceAccountGroup.userBalanceAccounts[
                            i
                        ].balanceAccountID
                    ] = '';
                } else {
                    orderCorpRequest[
                        balanceAccountGroup.userBalanceAccounts[
                            i
                        ].balanceAccountID
                    ] = '';
                }
            }
            setOrderRequest(orderRequest);

            // setOrderRequest(balanceAccountGroup.userBalanceAccounts);
        }

        if (newOrder.total != 0) {
            setOrderRequest(newOrder.benefits);

            let youBalance = {};
            let corpBalance = {};

            for (const key in newOrder.benefits) {
                if (newOrder.benefits[key].balanceAccountTypeID == 1) {
                    youBalance = {
                        ...youBalance,
                        [key]: newOrder.benefits[key].value
                    };
                } else if (newOrder.benefits[key].balanceAccountTypeID == 2) {
                    corpBalance = {
                        ...corpBalance,
                        [key]: newOrder.benefits[key].value
                    };
                }
            }

            setOrderYouRequest(youBalance);
            setOrderCorpRequest(corpBalance);
        }
    }, []);

    React.useEffect(() => {
        if (!Object.values(orderYouRequest).every((x) => x === '')) {
            setYouTotalValue(0);

            let sum = 0;

            for (const key in orderYouRequest) {
                if (orderYouRequest[key] != '') {
                    sum += orderYouRequest[key];
                }
            }

            setYouTotalValue(sum);
        }

        if (!Object.values(orderCorpRequest).every((x) => x === '')) {
            setCorpTotalValue(0);

            let sum = 0;

            for (const key in orderCorpRequest) {
                if (orderCorpRequest[key] != '') {
                    sum += orderCorpRequest[key];
                }
            }

            setCorpTotalValue(sum);
        }
    }, [orderYouRequest, orderCorpRequest]);

    const confirmOrderRequest = () => {
        dispatch(setBenefits(orderRequest));
        dispatch(setTotal(youTotalValue + corpTotalValue));
        navigate(`/orders/add/individual/${selectedUserInfo?.userID}/payment`);
    };

    return (
        <>
            <ResponsiveAppBar />

            <Container spacing={11}>
                <BackButton />
                {/* <p>{JSON.stringify}</p> */}

                <Tabs
                    value={tab}
                    onChange={(_, v) => {
                        setTab(v);
                    }}
                    aria-label="basic tabs example"
                >
                    <Tab label="Extrato" id={0} />
                    <Tab label="Saldo" id={1} />
                    <Tab label="Pedido" id={2} />
                </Tabs>
                <TabPanel value={tab} index={0}>
                    <h2>
                        Extrato {selectedUserInfo?.firstName}{' '}
                        {selectedUserInfo?.lastName}
                    </h2>
                    <UserExtractGridOrder userID={selectedUserInfo?.userID} />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                    <h2>
                        Detalhes {selectedUserInfo?.firstName}{' '}
                        {selectedUserInfo?.lastName}
                    </h2>
                    <BalanceTab value={tab} index={1} />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <Grid container display="flex" justifyContent="center">
                        {selectedUserInfo && (
                            <Grid
                                xs={12}
                                md={7}
                                display="flex"
                                justifyContent="center"
                                flexDirection="column"
                                alignItems="center"
                                rowGap={4}
                            >
                                <Typography
                                    alignSelf="flex-start"
                                    fontWeight={400}
                                    variant="h4"
                                >
                                    Criando novo pedido
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <Totalizer
                                            totalizerValue={
                                                currentTreasuryAccountBalance
                                            }
                                            totalizerText="Saldo Disponível"
                                            color="#4caf50"
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Totalizer
                                            totalizerValue={
                                                youTotalValue + corpTotalValue
                                            }
                                            totalizerText="Valor do Pedido"
                                            color="#EE7330"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid sx={{ width: '100%' }}>
                                    <Card
                                        sx={{
                                            minWidth: 275,
                                            boxShadow:
                                                '0px 0px 11px 0px rgba(0,0,0,0.25)',
                                            borderRadius: '10px',
                                            marginBottom: '20px'
                                        }}
                                    >
                                        <CardContent>
                                            <Grid
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    flexDirection: {
                                                        xs: 'column',
                                                        md: 'row'
                                                    },
                                                    marginBottom: '30px'
                                                }}
                                            >
                                                <Grid>
                                                    <Typography
                                                        gutterBottom
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    'bold'
                                                            }}
                                                        >
                                                            Nome:
                                                        </span>{' '}
                                                        {
                                                            selectedUserInfo.firstName
                                                        }{' '}
                                                        {
                                                            selectedUserInfo.lastName
                                                        }
                                                    </Typography>
                                                    <Typography
                                                        gutterBottom
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    'bold'
                                                            }}
                                                        >
                                                            E-mail:
                                                        </span>{' '}
                                                        {selectedUserInfo.email}
                                                    </Typography>
                                                </Grid>
                                                <Grid>
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    'bold'
                                                            }}
                                                        >
                                                            CPF:
                                                        </span>{' '}
                                                        {
                                                            selectedUserInfo.document
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                xs={12}
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    flexDirection: {
                                                        xs: 'column',
                                                        md: 'row'
                                                    },
                                                    marginBottom: '30px'
                                                }}
                                            >
                                                <Grid>
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        You
                                                    </Typography>
                                                </Grid>

                                                <Grid>
                                                    <Typography
                                                        variant="body1"
                                                        component="div"
                                                    >
                                                        <span
                                                            style={{
                                                                fontWeight:
                                                                    'bold'
                                                            }}
                                                        >
                                                            Total:
                                                        </span>{' '}
                                                        {fromFloatToCurrency(
                                                            youTotalValue
                                                        )}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid
                                                xs={12}
                                                display="flex"
                                                flexWrap="wrap"
                                                gap={3}
                                            >
                                                <ThemeProvider
                                                    theme={innerTheme}
                                                >
                                                    {balanceAccountGroup.userBalanceAccounts
                                                        .filter(
                                                            (x) =>
                                                                x.balanceAccountType ===
                                                                'You'
                                                        )
                                                        .map((account) => (
                                                            <InputWrapper
                                                                label={
                                                                    account.description
                                                                }
                                                                value={
                                                                    orderRequest[
                                                                        account
                                                                            .balanceAccountID
                                                                    ]?.value
                                                                }
                                                                onChange={(
                                                                    s
                                                                ) => {
                                                                    setOrderYouRequest(
                                                                        {
                                                                            ...orderYouRequest,
                                                                            [account.balanceAccountID]:
                                                                                s
                                                                        }
                                                                    );

                                                                    setOrderRequest(
                                                                        {
                                                                            ...orderRequest,
                                                                            [account.balanceAccountID]:
                                                                                {
                                                                                    value: s,
                                                                                    balanceAccountTypeID: 1
                                                                                }
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        ))}
                                                    <Grid
                                                        xs={12}
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'space-between',
                                                            flexDirection: {
                                                                xs: 'column',
                                                                md: 'row'
                                                            },
                                                            marginBottom: '30px'
                                                        }}
                                                    >
                                                        <Grid>
                                                            <Typography
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                Corp
                                                            </Typography>
                                                        </Grid>

                                                        <Grid>
                                                            <Typography
                                                                variant="body1"
                                                                component="div"
                                                            >
                                                                <span
                                                                    style={{
                                                                        fontWeight:
                                                                            'bold'
                                                                    }}
                                                                >
                                                                    Total:
                                                                </span>{' '}
                                                                {fromFloatToCurrency(
                                                                    corpTotalValue
                                                                )}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                                    {balanceAccountGroup.userBalanceAccounts
                                                        .filter(
                                                            (x) =>
                                                                x.balanceAccountType ===
                                                                'Corp'
                                                        )
                                                        .map((account) => (
                                                            <InputWrapper
                                                                label={
                                                                    account.description
                                                                }
                                                                value={
                                                                    orderRequest[
                                                                        account
                                                                            .balanceAccountID
                                                                    ]?.value
                                                                }
                                                                onChange={(
                                                                    s
                                                                ) => {
                                                                    setOrderCorpRequest(
                                                                        {
                                                                            ...orderCorpRequest,
                                                                            [account.balanceAccountID]:
                                                                                s
                                                                        }
                                                                    );

                                                                    setOrderRequest(
                                                                        {
                                                                            ...orderRequest,
                                                                            [account.balanceAccountID]:
                                                                                {
                                                                                    value: s,
                                                                                    balanceAccountTypeID: 2
                                                                                }
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        ))}
                                                </ThemeProvider>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{ marginBottom: '20px' }}
                                    onClick={confirmOrderRequest}
                                >
                                    CONFIRMAR PEDIDO
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </TabPanel>
                <Footer />
            </Container>
        </>
    );
};

const formatCurrency = (valueText) => {
    let value = parseFloat(valueText);

    const a = value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
};

const InputWrapper = ({ label, onChange, value }) => {
    return (
        <Grid sm={2}>
            <CurrencyTextField
                label={label}
                variant="standard"
                value={value}
                placeholder="0,00"
                currencySymbol="R$"
                //minimumValue="0"
                decimalCharacter=","
                digitGroupSeparator="."
                // onChange={onChange}
                onChange={(e, value) => onChange(value)}
            />
        </Grid>
    );
};

export default CreateNewOrder;
