import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { CHANGE_COMPANY, getUserData } from '../store/_Entities/User.ts';
import { useNavigate } from 'react-router-dom';

import LoadingAction from '../components/LoadingAction.jsx';
import logo from '../assets/images/logow.png';

const SelectCompany = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.entities);
    const navigate = useNavigate();

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        dispatch(getUserData(user.authData.sub));
    }, []);

    useEffect(() => {
        if (user.data) {
            const activeCompanies = user.data.companies.filter(
                (x) => x.active === true
            );
            setCompanies(activeCompanies);
            if (activeCompanies.length === 1) {
                navigate('/home');
            }
        }
    }, [user.data]);

    const handleChangeCompany = (event) => {
        const company = companies.filter(
            (x) => x.companyID === event.target.value
        );
        dispatch(CHANGE_COMPANY(company[0]));
    };

    return (
        <div className="login">
            <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ minHeight: '100vh' }}
            >
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    xs={12}
                    md={6}
                    sx={{ minHeight: '50vh' }}
                >
                    <Grid
                        xm={12}
                        maxWidth={600}
                        sx={{
                            background: 'white',
                            padding: '25px 100px',
                            borderRadius: '10px'
                        }}
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <img
                            src={logo}
                            alt="logo"
                            style={{ width: '150px', marginBottom: '20px' }}
                        />

                        {user.loading ? (
                            <LoadingAction loading={user.loading} />
                        ) : (
                            <>
                                <Typography>Selecione uma empresa</Typography>

                                <Select
                                    SelectDisplayProps={{
                                        style: {
                                            border: '2px solid #EE7330',
                                            margin: '20px 0px',
                                            whiteSpace: 'normal',
                                            maxWidth: '350px'
                                        }
                                    }}
                                    variant="outlined"
                                    value={user?.data?.companyID}
                                    onChange={handleChangeCompany}
                                    autoWidth
                                    sx={{
                                        '& fieldset': {
                                            border: 'none'
                                        }
                                    }}
                                >
                                    {companies.map((company) => {
                                        return (
                                            <MenuItem
                                                key={company.companyID}
                                                value={company.companyID}
                                            >
                                                {company.companyName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        navigate('/home');
                                    }}
                                >
                                    Continuar
                                </Button>
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default SelectCompany;
