import * as React from 'react';

// MUI
import CircularProgress from '@mui/material/CircularProgress';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducer';

export default function LoadingProvider() {
    const { loading } = useSelector((state: RootState) => state.ui);

    return (
        <div
            style={{
                display: loading?.isLoading ? 'flex' : 'none',
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(255,255,255,0.25)',

                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <CircularProgress
                style={{ margin: 'auto' }}
                size={50}
                variant="indeterminate"
            />
        </div>
    );
}
