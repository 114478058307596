import React, { useState, useEffect } from 'react';

import { DataGrid, ptBR } from '@mui/x-data-grid';
import { EditButton } from '../EditButton';
import { DocumentButton } from '../DocumentButton';
import { fromFloatToCurrency } from '../../utils/format';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { maxWidth, minWidth, width } from '@mui/system';
import applyTooltipToColumns from '../../utils/applyTooltipToColumn';

const columns = [
    {
        field: 'user',
        headerName: 'Usuário',
        headerAlign: 'center',
        overflow: '',
        align: 'center',
        flex: 1,
        minWidth: 100
    },
    {
        field: 'addedTime',
        headerName: 'Data da transação',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 100
    },
    {
        field: 'currency',
        headerName: 'Moeda',
        headerAlign: 'center',
        align: 'center',
        width: 70
    },
    {
        field: 'txnAmount',
        headerName: 'Valor Pago',
        renderCell: (params) => fromFloatToCurrency(params.row.txnAmount),
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 20
    },
    {
        field: 'billingAmountAuthorized',
        headerName: 'Valor Convertido',
        renderCell: (params) =>
            fromFloatToCurrency(params.row.billingAmountAuthorized),
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 60
    },
    {
        field: 'mcc',
        headerName: 'MCC',
        headerAlign: 'center',
        align: 'center',
        width: 60
    },
    {
        field: 'merchantName',
        headerName: 'Descrição',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 100
    },
    {
        field: 'merchantCity',
        headerName: 'Cidade',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
        minWidth: 100
    },
    {
        field: 'panMasked',
        headerName: 'Número do Cartão',
        headerAlign: 'center',
        align: 'center',
        flex: 1
    }
];

export default function TransactionGrid({
    companyTransactions,
    setSkip,
    total
}) {
    const [pageState, setPageState] = useState({
        page: 0,
        pageSize: 20
    });

    const handlePageChange = (newPage) => {
        console.log(newPage);
        setPageState((old) => ({ ...old, page: newPage }));
        setSkip(newPage * pageState.pageSize);
    };

    return (
        <div style={{ height: '100%', width: '100%', maxWidth: '1500px' }}>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={companyTransactions?.transactions}
                rowCount={total}
                disableSelectionOnClick
                autoHeight
                columns={applyTooltipToColumns(columns, 20)}
                pageSize={pageState.pageSize}
                pagination
                paginationMode="server"
                getRowId={(row) =>
                    companyTransactions?.transactions?.indexOf(row)
                }
                rowsPerPageOptions={[pageState.pageSize]}
                page={pageState.page}
                onPageChange={(newPage) => {
                    handlePageChange(newPage);
                }}
            />
        </div>
    );
}
