import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';

import logo from '../assets/images/logow.png';
import userIcon from '../assets/images/user.png';
import { useDispatch, useSelector } from 'react-redux';
import { USER_LOGOUT } from '../store/_Entities/User';
import { Link, useNavigate } from 'react-router-dom';
import WexpIconOrangeCancel from '../assets/images/cancel.png';
import { Grid, Modal } from '@mui/material';
import WexpIconOrangeSuccess from '../assets/images/corrigir.png';
import { GetTreasuryAccountHolder } from '../store/_Entities/TreasuryAccountHolder';
import { fromFloatToCurrency } from '../utils/format';

const pages = [
    { key: 'Início', href: '/home' },
    { key: 'Colaboradores', href: '/collaborators' },
    { key: 'Administradores', href: '/admin' },
    // { key: 'Cartões', href: '/cards' },
    { key: 'Saldo', href: '/balance' },
    { key: 'Pedidos', href: '/orders' },
    { key: 'Transações', href: '/transaction' }
];

const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const ResponsiveAppBar = ({ onlyExit }) => {
    const { user, treasuryAccountHolder } = useSelector(
        (state) => state.entities
    );

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [showLogoutModal, setShowLogoutModal] = React.useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    React.useEffect(() => {
        if (user.data?.companyID) {
            dispatch(GetTreasuryAccountHolder(user.data.companyID));
        }
    }, [user.data?.companyID]);

    const settings = [
        {
            key: 'Dados da Conta',
            onClick: () => {
                navigate('/account');
            }
        },
        {
            key: 'Endereços',
            onClick: () => {
                navigate('/address');
            }
        },
        {
            key: 'Administradores',
            onClick: () => {
                navigate('/admin');
            }
        },
        {
            key: 'Sair',
            onClick: () => {
                setShowLogoutModal(true);
            }
        }
    ];

    const confirmLogout = () => {
        dispatch(USER_LOGOUT());
        navigate('/login');
    };

    return (
        <>
            <AppBar
                position="static"
                sx={{ background: '#fff', color: 'black' }}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            <img src={logo} alt="" style={{ width: '75px' }} />
                        </Typography>

                        {!onlyExit && (
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    display: { xs: 'flex', md: 'none' }
                                }}
                            >
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={(event) =>
                                        setAnchorElNav(event.currentTarget)
                                    }
                                    color="inherit"
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={() => setAnchorElNav(null)}
                                    sx={{
                                        display: { xs: 'block', md: 'none' }
                                    }}
                                >
                                    {pages.map((page) => (
                                        <Link
                                            to={page.href}
                                            style={{
                                                textDecoration: 'none',
                                                color: '#000'
                                            }}
                                        >
                                            <MenuItem
                                                {...page}
                                                onClick={() =>
                                                    setAnchorElNav(null)
                                                }
                                            >
                                                <Typography textAlign="center">
                                                    {page.key}
                                                </Typography>
                                            </MenuItem>
                                        </Link>
                                    ))}
                                </Menu>
                            </Box>
                        )}
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'flex', md: 'none' }
                            }}
                        >
                            <img
                                style={{ height: '2rem' }}
                                src={logo}
                                alt="logo"
                            />
                        </Typography>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: 'none', md: 'flex' }
                            }}
                        >
                            {!onlyExit &&
                                pages.map((page) => (
                                    <Button
                                        size="small"
                                        {...page}
                                        onClick={() => setAnchorElNav(null)}
                                        sx={{
                                            my: 2,
                                            mx: 0.5,
                                            minWidth: 'unset',
                                            color: 'black',
                                            textTransform: 'capitalize',
                                            fontSize: '15px'
                                        }}
                                    >
                                        {page.key}
                                    </Button>
                                ))}
                        </Box>
                        <Box marginRight={2}>
                            <Typography variant="button">
                                Saldo:{' '}
                                {fromFloatToCurrency(
                                    treasuryAccountHolder.treasuryAccountHolder
                                        ?.treasuryAccounts[0]
                                        ?.balanceAccounts[0]?.balance
                                )}
                            </Typography>
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>
                            <Button
                                variant="outlined"
                                className="comp-btn"
                                sx={{
                                    display: { xs: 'none', md: 'inline-flex' }
                                }}
                            >
                                {user?.data?.company}
                            </Button>
                            <Tooltip title="Open settings">
                                <IconButton
                                    onClick={(event) =>
                                        setAnchorElUser(event.currentTarget)
                                    }
                                    sx={{ p: 0 }}
                                >
                                    <Avatar alt="Remy Sharp" src={userIcon} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={() => setAnchorElUser(null)}
                            >
                                {settings
                                    .filter(
                                        (setting) =>
                                            !(
                                                onlyExit &&
                                                !(setting.key === 'Sair')
                                            )
                                    )
                                    .map((setting) => (
                                        <MenuItem
                                            key={setting.key}
                                            onClick={setting.onClick}
                                        >
                                            <Typography textAlign="center">
                                                {setting.key}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Modal
                open={showLogoutModal}
                onClose={() => setShowLogoutModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="modal-login" sx={modalContentStyle}>
                    <img
                        className="cancel"
                        src={WexpIconOrangeCancel}
                        alt="Wexp Icon orange cancel"
                        onClick={() => setShowLogoutModal(false)}
                    />
                    <Grid
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '35px',
                            padding: '20px'
                        }}
                    >
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                        >
                            Tem certeza que deseja sair ?
                        </Typography>
                        <Grid>
                            <Button
                                onClick={confirmLogout}
                                sx={{ marginRight: '40px' }}
                                variant="contained"
                            >
                                Confirmar
                            </Button>
                            <Button onClick={() => setShowLogoutModal(false)}>
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
};
export default ResponsiveAppBar;
