import { useState } from 'react';

// MUI
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// Wexp Components
import ResponsiveAppBar from '../../components/NavBar';
import BackButton from '../../components/BackButton';
import Footer from '../../components/Footer';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExtractGridOrder from '../../components/grids/ExtractGridOrder';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCompanyTransferStatements } from '../../store/_Entities/Statement';
import { Box } from '@mui/material';
import ExportToExcelButton from '../../components/xlsx';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import ptLocale from 'date-fns/locale/pt-BR';
import { LocalizationProvider } from '@mui/x-date-pickers';
import validateDate from '../../utils/validateDate';

const rows = [
    {
        id: 1,
        orderName: 'Suporteee',
        creationDate: '19/11/2021',
        status: 'Pago',
        value: 200
    },
    {
        id: 2,
        orderName: 'Suporte',
        creationDate: '20/11/2021',
        status: 'Pago',
        value: 310
    }
];

const Orders = () => {
    const dispatch = useDispatch();
    const { user, statement } = useSelector((state) => state.entities);

    var defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 1);
    defaultStartDate.setHours(0, 0, 0, 0);

    var defaultEndDate = new Date();
    defaultEndDate.setHours(0, 0, 0, 0);

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
    };

    const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
    };

    useEffect(() => {
        if (validateDate(startDate) && validateDate(endDate)) {
            dispatch(
                getCompanyTransferStatements(
                    user.data?.companyID,
                    startDate.toLocaleDateString('en-CA'),
                    endDate.toLocaleDateString('en-CA')
                )
            );
        }
    }, [startDate, endDate]);

    return (
        <>
            <ResponsiveAppBar />
            <Container spacing={11}>
                <BackButton />

                <Grid container rowGap={8}>
                    <Grid
                        container
                        xs={12}
                        columnGap={6}
                        rowGap={6}
                        flexWrap="nowrap"
                    >
                        <Grid item xs={12} md={4}>
                            <Card sx={Styles.cardStyle}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Adicionar Individualmente
                                    </Typography>
                                    <Typography
                                        sx={Styles.greyText}
                                        color="text.secondary"
                                    >
                                        Selecione os colaboradores
                                        individualmente
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        href="/orders/add/individual"
                                    >
                                        Adicionar
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Card sx={Styles.cardStyle}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Adicionar por planilha
                                    </Typography>
                                    <Typography
                                        sx={Styles.greyText}
                                        color="text.secondary"
                                    >
                                        Para pedidos com grande número de
                                        colaboradores
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        href="/orders/add/batch"
                                    >
                                        Adicionar
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                        {user?.data?.approver && (
                            <Grid item xs={12} md={4}>
                                <Card sx={Styles.cardStyle}>
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                        >
                                            Aprovar Transferência
                                        </Typography>
                                        <Typography
                                            sx={Styles.greyText}
                                            color="text.secondary"
                                        >
                                            Aprovação de Transferência
                                            solicitada
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            href="/orders/approve-tranfer"
                                        >
                                            Aprovar
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography variant="h6">
                                Pedidos Passados
                            </Typography>
                            <ExportToExcelButton
                                columns={{
                                    created: 'Data',
                                    description: 'Pedido',
                                    totalAmount: 'Valor'
                                }}
                                rows={statement?.statements}
                                filename="Pedidos"
                            >
                                Exportar para Excel
                            </ExportToExcelButton>
                        </Box>
                        <Grid
                            sx={{
                                display: 'flex',
                                gap: '20px',
                                marginBottom: '20px',
                                flexDirection: { xs: 'column', md: 'row' }
                            }}
                        >
                            <Grid>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptLocale}
                                >
                                    <DateTimePicker
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        label="De"
                                        views={['year', 'month', 'day']}
                                        inputFormat="dd/MM/yyyy"
                                        mask="__/__/____"
                                        value={startDate}
                                        onChange={handleStartDateChange}
                                        disableFuture
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    locale={ptLocale}
                                >
                                    <DateTimePicker
                                        renderInput={(props) => (
                                            <TextField {...props} />
                                        )}
                                        label="Até"
                                        views={['year', 'month', 'day']}
                                        inputFormat="dd/MM/yyyy"
                                        mask="__/__/____"
                                        value={endDate}
                                        onChange={handleEndDateChange}
                                        maxDate={defaultEndDate}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                        <ExtractGridOrder
                            rows={statement?.statements}
                            loading={statement?.loading}
                        />
                    </Grid>
                </Grid>
                <Footer />
            </Container>
        </>
    );
};

export default Orders;

const Styles = {
    cardStyle: {
        borderRadius: 2,
        padding: '0.5rem',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)'
    },
    greyText: {
        mb: 1.5,
        minHeight: '3rem'
    }
};
