// MUI
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTransferStatements } from '../../store/_Entities/Statement';
import clsx from 'clsx';
import { fromFloatToCurrency } from '../../utils/format';

export default function UserExtractGridOrder({ userID }) {
    const dispatch = useDispatch();
    const { statement } = useSelector((state) => state.entities);

    useEffect(() => {
        if (userID) {
            dispatch(getUserTransferStatements(userID));
        }
    }, [userID]);

    const sizeCol = 150;
    const columns = [
        {
            field: 'created',
            headerName: 'Data',
            minWidth: sizeCol,
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            valueGetter: (params) => {
                const dateString = params.value;
                const [day, month, year] = dateString.split('/');
                return new Date(`${year}-${month}-${day}`);
            },
            renderCell: (params) => {
                const date = new Date(params.value);
                return date.toLocaleDateString('pt-BR');
            }
        },
        {
            field: 'description',
            headerName: 'Pedido',
            minWidth: 600,
            headerAlign: 'left',
            overflow: '',
            align: 'left',
            flex: 1
        },
        {
            field: 'totalAmount',
            headerName: 'Valor',
            renderCell: (params) => fromFloatToCurrency(params.row.totalAmount),
            minWidth: sizeCol,
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            cellClassName: (params) => {
                if (params.value == null) {
                    return '';
                }

                return clsx('number-handler', {
                    negative: params.value < 0,
                    positive: params.value > 0
                });
            }
        }
    ];

    return (
        <div style={{ height: 440, width: '100%' }}>
            <DataGrid
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                rows={statement?.statements || []}
                disableSelectionOnClick
                columns={columns}
                pageSize={5}
                getRowId={(row) => row.orderID}
                rowsPerPageOptions={[5]}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'created', sort: 'desc' }]
                    }
                }}
            />
        </div>
    );
}
