import { Tooltip, Box, Typography } from '@mui/material';

const applyTooltipToColumns = (columns, maxLengthForTooltip) => {
    return columns.map((column) => {
        const originalRenderCell = column.renderCell;

        return {
            ...column,
            headerAlign: 'start',
            align: 'start',
            renderCell: (params) => {
                let value = params.value;

                if (column.valueFormatter) {
                    value = column.valueFormatter(params);
                }

                if (originalRenderCell) {
                    value = originalRenderCell(params);
                }

                const shouldShowTooltip =
                    value && value.length > maxLengthForTooltip;

                return shouldShowTooltip ? (
                    <Tooltip
                        title={
                            <Typography variant="subtitle2">{value}</Typography>
                        }
                        placement="top"
                    >
                        <Box sx={{ overflow: 'hidden' }}>
                            {value.substring(0, maxLengthForTooltip) + '...'}
                        </Box>
                    </Tooltip>
                ) : (
                    value
                );
            }
        };
    });
};

export default applyTooltipToColumns;
