export const moneyFormat = (value) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

export const fromFloatToCurrency = (value) => {
    return parseFloat(value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
    });
}

export const formatDateToPtLocale = (date) => {
    const dateObj = new Date(date);

    return dateObj.toLocaleDateString("pt-BR", {timeZone: 'UTC'});
}

export const formatDateToPtLocaleWithTime = (date) => {
    const dateObj = new Date(date);

    return dateObj.toLocaleString("pt-BR", {
        timeZone: "UTC",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
};


/*
* Receives a date on pt-BR format (dd-mm-yyyy) and converts to (yyyy-mm-dd)
*/
export const formatDateStringToSwapFormat = (date) => {
    let separatedDate = date.toString().split('/');

    return separatedDate[2] + "-" + separatedDate[1] + "-" + separatedDate[0];
}
